<template>
	<div class="container">
		<lightBox ref="lightBox" :actIndex=actIndex :images=images :animation="true"/>
		

		<div class="cols2_2-8">
			<div v-if="imgs">
				<img v-for="img in imgs" :src="$store.state.server + '/' + img.path" :alt="img.alt_text" class="img1"  :title="img.title"  @click="showLighBoxMulti">
			</div>

			
			
			<div> <!-- right -->
				<h2 class="cap">Gartengestaltung</h2>
				<p class="cap bor1">
					<span class="f2">ist eine Kunst des Sehens, der Phantasie und des Handwerks.
					Die Planung ist die Fähigkeit auf einem Blatt Papier Räume zu schaffen.
					Rechtzeitig eingesetzt,bewirkt die Planung,dass ein Garten kein Flickwerk,
					sondern ein gesamtes Ganzes wird.</span> <span class="smaler">(Peter Pauritsch)</span>
				</p>


				<h4 style="margin-top: 2.1rem">Wir bieten im Rahmen der Planung folgende Leistungen:</h4>

				<p class="cap">
					Besichtigung vor Ort mit  Informationsgespräch und falls nötig Vermessung, Erstellen einer Gestaltungsskizze nötige Detailplanungen Bepflanzungsskizze mit Pflanzliste
				</p>
<!--  -->
				<p class="cap">
					Beim 1. Gespräch wird die Ist-Situation aufgenommen, Wünsche und Vorstellungen werden besprochen und Eventualitäten geklärt. Auf dieser Basis entwickeln wir ein Konzept. Wenn alles geklärt ist, erhalten Sie von uns Ihr Gestaltungskonzept mit allen  Skizzen und der Pflanzliste.
				</p>		

				<p class="cap"></p>
				<h2 class="cap">Beratung</h2>
				<p class="cap">
					Natürlich kommen wir gerne auch nur zu einer Gartenberatung bei Ihnen vorbei. Unsere Gärtnermeister unterstützen Sie bei allen Gartenfragen- und Problemen (Rasen, Teich, Umsetzen von Sträuchern und Stauden, Schädlingsbekämpfung, Düngung oder Umgestaltung) bei  einem  Gespräch vor Ort.
				</p>

			</div>

		</div> <!-- end of cols2_3-7 -->




	</div>
</template>

<script>
	// import  dialogComp from "@/components/DialogComp.vue";
	import axios from 'axios';
	import * as tools from '@/tools.js'; 
	import  lightBox from "@/components/LightBox.vue";


	export default {
		name: 'GG_Beratung_Planung',
		components: {
			// dialogComp,
			lightBox,

		},
		mounted(){
			this.getImgs();

		}, // end mounted 
		data: 
		function(){
			return {
				images: [],
				actIndex: null,
				imgs: null,

			}
		}, // end data
		methods: {
			getImgs(){

				let self = this;
				let url = this.$store.state.server + '/api/getImagesIndex.php';

				axios.post(url, {
					crossDomain: true,
					headers: {
					},
					data: { slug: 'gartengestaltung-beratung' },
				})
				.then(function (resp) {


					self.imgs = resp.data.data[0];
					// self.pk = self.pkFilter = resp.data.data[1];

				});
			},
			showLighBoxMulti(e){

				this.images = []
				let coll = document.getElementsByClassName("container")[0].getElementsByTagName("img");

				let selSrc = e.target.src;

				let i = -1;
				    for (let item of coll) {
				    i++;

            if (item.src === selSrc) {
            	this.actIndex = i;
            }
            
				    this.images.push({src: item.src, height: item.naturalHeight, width: item.naturalWidth, title: item.title, alt: item.alt});
        }
			}


		} // end methods
	} // end export default

</script>



<style scoped lang="scss">
	.container {
		height: 100%;
	}

	.img1 {
		margin-bottom: 2rem;
				cursor: pointer;
	}


</style>
